<template>
  <section class="mainText ">
    <b-container fluid="xl">
        <b-row class="maintText__row">
        <b-col cols="12" class="mainText__mainName side-gap">

          <h2 style="mainText__mainName--maintxtx">
            Érezd magad biztonságban!
          </h2>
          <p class="hashText">– Barátok a bajban</p>
        </b-col>
      </b-row>
        <b-row class="maintText__row">
            <b-col cols="12" lg="10" class="side-gap offset-lg-1">
            <div class="maintText__txt-content pb-lg-5 pb-2">
                <p class="text">
                Fontos, hogy olyan helyzetekben is biztonságban érezzük magunkat, amikor hazafelé tartunk egy átbulizott éjszaka után, vagy épp egy veszélyes környéken vagyunk kénytelenek áthaladni. Szerencsére okostelefonunk a megfelelő mobilalkalmazás segítségével ebben is segítségünkre lehet! </p>

                <p class="text">
                Az I’m safe – Women Safety App egy kifejezetten nők számára fejlesztett ingyenes applikáció, melynek több funkciója is növelheti a biztonságunkat:</p>

                <p class="text">
                -	A Track me lehetővé teszi, hogy akár csak egy korlátozott időre megosszuk ismerőseinkkel a tartózkodási helyzetünket. Így például, ha tudjuk, hogy a szokásos napi futókörünk egy órán át tart, beállíthatjuk, hogy a kijelölt személyek csak ebben az egy órában követhessék mozgásunkat.</p>
                <p class="text">
                -	Az SOS funkció célja, hogy amikor már megtörtént a baj, automatikusan értesítse ismerőseinket a veszélyhelyzetről, és meghatározott időközönként képi- és hangfelvételeket készítsen, ezzel is elősegítve a bizonyítékok gyűjtését.</p>
                <p class="text">
                Az alkalmazás folyamatos fejlesztés alatt áll, hamarosan egy új funkció is elérhetővé válik, amely egy ötfokú skála által segít beazonosítani egy város biztonságosnak és veszélyesnek ítélhető területeit. A fejlesztők további célja, hogy férfiak, illetve idősek számára is hasonló alkalmazást hozzanak létre.  </p>
                <p class="text">
                A cikkben említett applikáción kívül az okosórák is segíthetnek nekünk vész esetén. A Galaxy Watch4 szériás és ennél újabb verziójú Samsung okosórákon beállítható, hogy a Kezdőlap gomb többszöri megnyomása esetén megosszuk a tartózkodási helyünket az előre beállított kontaktjainkkal, vagy akár a segélyhívó szám is hívható. Segélykérés esetén az óra előre beállított orvosi információinkat (például a vércsoportunkat) is megjeleníti kijelzőjén. Érdemes előre felkészülni az esetleges vészhelyzetekre, és észben tartani azt, hogy az okoseszközök cinkostársak a felhőtlen pillanatokban és barátok a bajban.</p>
            </div>
            </b-col>
            <b-col cols="12" class="order-4 order-lg-1">
              <img src="images/safe.jpg" class="w-100 margin-top-sm-44 mb-4"/>
          </b-col>
        </b-row>
    </b-container>
    <Articles :isFirst="false"/>
  </section>
</template>
<script>
import Articles from '../components/Articles.vue'

export default {
  components: {
    Articles
  },
  mounted(){

    this.setMetaTags({        
        title: "Érezd magad biztonságban", 
        desc: "Fontos, hogy olyan helyzetekben is biztonságban érezzük magunkat, amikor hazafelé tartunk egy átbulizott éjszaka után, vagy épp egy veszélyes környéken vagyunk kénytelenek áthaladni. ", 
        keywords: null, 
        ogType: 'website', 
        ogTitle: "Érezd magad biztonságban!",
        ogImage: null, 
        ogImageWidth: null, 
        ogImageHeight: null, 
        ogDesc: "Fontos, hogy olyan helyzetekben is biztonságban érezzük magunkat, amikor hazafelé tartunk egy átbulizott éjszaka után, vagy épp egy veszélyes környéken vagyunk kénytelenek áthaladni. "        
    })
  }
}
</script>